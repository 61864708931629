<template>
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" :class="theme">
        <g>
            <path d="M17.7938 8.5023C17.7936 8.50209 17.7934 8.50184 17.7931 8.50163L14.1192 4.84538C13.8439 4.57148 13.3988 4.5725 13.1248 4.84777C12.8508 5.12301 12.8519 5.56819 13.1271 5.84213L15.5938 8.29688H0.703125C0.314789 8.29688 0 8.61167 0 9C0 9.38834 0.314789 9.70313 0.703125 9.70313H15.5938L13.1272 12.1579C12.8519 12.4318 12.8509 12.877 13.1248 13.1522C13.3988 13.4275 13.844 13.4285 14.1192 13.1546L17.7932 9.49838C17.7934 9.49817 17.7936 9.49792 17.7938 9.49771C18.0692 9.22286 18.0683 8.77623 17.7938 8.5023Z" />
        </g>
    </svg>
</template>

<script>

    export default {
        name: 'Arrow',
        props: {
            theme: { type: String, default: 'white', validator: value => {
                return ['white', 'grey'].indexOf(value) !== -1;
            }},
        }
    }

</script>

<style lang="scss" scoped>

    @import '../../styles/_variables.scss';

    .white path, .white rect {
        fill: color(White);
    }

    .grey path, .grey rect {
        fill: color(Emperor);
    }

</style>
