<template>
    <div class="Loading">
        <div class="dots" />
    </div>
</template>

<script>

    export default {
        name: 'Loading',
    }

</script>

<style lang="scss" scoped>

    @import '../styles/_variables.scss';

    .Loading {

        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 999;

        background-color: color(White, 1);

        display: flex;
        align-items: center;
        justify-content: center;

    }

    .dots {

        position: relative;

        width: 30px;
        height: 30px;

    }

    .dots:before,
    .dots:after {

        content: '';

        position: absolute;
		top: 0;
		left: 0;

		width: inherit;
        height: inherit;

        border-radius: 50%;
		background-color: color(OldGold);

		animation: pulse 2.0s infinite ease-in-out;
        opacity: 0.6;

    }

    .dots:after {
        animation-delay: -1.0s;
    }

    @keyframes pulse {
        0%,
        100% {
            transform: scale(0.0);
        }
        50% {
            transform: scale(1.0);
        }
    }

</style>
