// Firebase.
import { database } from '@/firebase/config.js';

export function getAlerts(userId) {
    return database.ref(`profiles/${ userId }/alerts`).once('value');
};

export function getAlert(id) {
    return database.ref(`alerts/${ id }`).once('value');
};

export function markAsRead(userId, alertId) {
    return database.ref(`profiles/${ userId }/alerts/${ alertId }/read`).set(true);
};
