<template>
    <div class="Confirm">
        <div class="card">
            <h4><slot /></h4>
            <div class="buttons">
                <button class="okay" @click="$emit('okay')">{{ okay }}</button>
                <button @click="$emit('cancel')">{{ cancel }}</button>
            </div>
        </div>
    </div>
</template>

<script>

    export default {
        name: 'Confirm',
        props: {
            okay: { type: String, default: 'Okay' },
            cancel: { type: String, default: 'Cancel' },
        }
    }

</script>

<style lang="scss" scoped>

    @import '../styles/_variables.scss';

    .Confirm {

        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 100;

        background-color: color(Black, 0.25);

        display: flex;
        align-items: center;

    }

    .card {
        padding: 0;
        overflow: hidden;
    }

    h4 {

        padding: size(Medium);

        line-height: 1.25;
        text-align: center;
        color: color(Emperor);

    }

    .buttons {
        display: flex;
        border-top: 1px dashed color(Emperor, 0.15);
    }

    button {

        appearance: none;
        outline: none;
        border: none;

        font-family: 'Open Sans', sans-serif;
        font-weight: 700;
        font-size: 12px;
        color: color(Emperor);
        text-transform: uppercase;

        background: color(White);

        width: 50%;
        padding: size(Medium);

        display: flex;
        align-items: center;
        justify-content: center;

    }

    button.okay {
        color: color(OldGold);
        border-right: 1px dashed color(Emperor, 0.15);
    }

</style>
