<template>
    <div class="Menu">
        <div class="bg" @click="close" ref="bg" />
        <div class="sidebar pages" :class="{ open: sidebar === 'pages' }" ref="pages">
            <button @click="close"><Arrow theme="grey" />Back</button>
            <router-link v-for="cell of pages" :key="cell.id" :to="cell.to" :class="{ active: $route.name === cell.to.name || cell.alias.includes($route.name) }">
                <div class="cell" @click="onClick(cell.id)">
                    <Cell :theme="$route.name === cell.to.name || cell.alias.includes($route.name) ? 'gold' : 'grey'" :icon="cell.id" />
                    <h4>{{ cell.to.name }}</h4>
                </div>
            </router-link>
        </div>
        <div class="sidebar personal" :class="{ open: sidebar === 'personal' }" ref="personal">
            <button @click="close">Back<Arrow theme="grey" /></button>
            <router-link v-for="cell of personal" :key="cell.id" :to="{ name: cell.name }" :class="{ active: $route.name === cell.name }">
                <div class="cell" @click="onClick(cell.id)">
                    <Cell :theme="$route.name === cell.name ? 'gold' : 'grey'" :icon="cell.id" />
                    <h4>{{ cell.name }}</h4>
                    <div class="alert" v-if="cell.id === 'alert' && $store.state.hasUnreadAlerts" />
                </div>
            </router-link>
            <div class="cell" @click="onClick('logout')">
                <Cell theme="grey" icon="logout" />
                <h4>Logout</h4>
            </div>
        </div>
        <Confirm v-if="isLogout" okay="Yes" @okay="logout" @cancel="cancel">Are you sure you want to log out?</Confirm>
    </div>
</template>

<script>

    // Modules.
    import gsap from 'gsap';

    // Components.
    import Arrow from '@/components/vectors/Arrow.vue';
    import Cell from '@/components/vectors/Cell.vue';
    import Confirm from '@/components/Confirm.vue';

    // Firebase.
    import { logout } from '@/firebase/auth.js';

    export default {
        name: 'Menu',
        components: {
            Arrow,
            Cell,
            Confirm,
        },
        data() {
            return {
                pages: [
                    { id: 'book', to: { name: 'Books', params: { book_id: '0' } }, alias: [] },
                    { id: 'bet', to: { name: 'Bets' }, alias: ['SettledBets', 'VoidedBets', 'Profit'] },
                    { id: 'manage', to: { name: 'Manage' }, alias: ['CreateBook', 'Members', 'InviteMembers', 'Wagers', 'CreateWager', 'EditWager'] },
                    { id: 'help', to: { name: 'Help' }, alias: [] },
                ],
                personal: [
                    { id: 'profile', name: 'Profile' },
                    { id: 'alert', name: 'Alerts' },
                ],
                isLogout: false,
            }
        },
        props: {
            sidebar: { type: String, validator: value => {
                return [null, 'pages', 'personal'].indexOf(value) !== -1;
            }},
        },
        methods: {
            onClick(id) {
                switch (id) {
                    default:
                        // this.$emit('close');
                        this.close();
                        break;
                    case 'logout':
                        this.isLogout = true;
                        break;
                }
            },
            logout() {

                this.isLogout = false;
                this.$store.dispatch('updateLoading', 1);

                logout().then(() => {
                    this.$emit('close');
                    this.$router.replace({ name: 'Home' });
                    this.$store.dispatch('reset');
                    this.$store.dispatch('updateLoading', -1);
                }).catch(error => {
                    console.error(error);
                    this.$store.dispatch('updateLoading', -1);
                });

            },
            cancel() {
                this.isLogout = false;
            },
            close() {
                gsap.to(this.$refs.pages, { x: '-100%', duration: 0.75, ease: 'power3.inOut' });
                gsap.to(this.$refs.personal, { x: '100%', duration: 0.75, ease: 'power3.inOut' });
                gsap.to(this.$refs.bg, { autoAlpha: 0, duration: 0.75, ease: 'sine.out', onComplete: () => this.$emit('close') });
            }
        },
        mounted() {
            gsap.set(this.$refs.pages, { x: '-100%' });
            gsap.set(this.$refs.personal, { x: '100%' });
            gsap.to(this.$refs[this.$props.sidebar], { x: '0%', duration: 0.75, ease: 'power3.inOut' });
            gsap.fromTo(this.$refs.bg, { autoAlpha: 0 }, { autoAlpha: 1, duration: 0.75, ease: 'sine.out' });
        }
    }

</script>

<style lang="scss" scoped>

    @import '../styles/_variables.scss';

    .Menu {

        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 100;

        display: flex;
        justify-content: space-between;

        @supports (backdrop-filter: none) {
            // backdrop-filter: blur(3px);
        }

    }

    .bg {

        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;

        background-color: color(Black, 0.25);

    }

    .sidebar {

        width: 120px;
        height: 100%;

        background-color: color(White);

    }

    .sidebar.pages {
        // transform: translateX(-100%);
    }

    .sidebar.personal {
        // transform: translateX(100%);
    }

    .sidebar.open {
        // transform: translateX(0);
    }

    .sidebar button {

        position: relative;
        z-index: 5;

        appearance: none;
        outline: none;
        border: none;

        font-family: 'Open Sans', sans-serif;
        font-weight: 700;
        font-size: 12px;
        color: color(Emperor);
        text-transform: uppercase;

        background: color(White);
        box-shadow: 0 0 15px color(Black, 0.1);

        width: 100%;
        height: 50px;

        display: flex;
        align-items: center;
        justify-content: center;

    }

    .sidebar.pages button svg {
        margin-right: 15px;
        transform: scaleX(-1);
    }

    .sidebar.personal button svg {
        margin-left: 15px;
    }

    .cell {

        position: relative;

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        height: 120px;

        border-bottom: 1px solid color(Emperor, 0.15);

    }

    .cell h4 {
        margin-top: size(Small);
        text-transform: uppercase;
    }

    a.active .cell {
        background-color: color(WildSand);
    }

    a {
        text-decoration: none;
        color: color(Emperor);
    }

    .alert {
        position: absolute;
        top: size(Small);
        right: size(Small);
    }

</style>

<style lang="scss">

    .Menu .cell svg {
        width: size(Large);
        height: size(Large);
    }

    .Menu .cell svg,
    .Menu .cell h4 {
        opacity: 0.25;
    }

    .Menu a.active .cell svg,
    .Menu a.active .cell h4 {
        opacity: 1;
    }

</style>
