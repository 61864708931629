<template>
    <div>
        <div class="card card--form">
            <img class="lockup" src="/images/lockup.svg" alt="BARF Book">
            <form @submit="submit">
                <input type="email" placeholder="Email" v-model="email">
                <input type="password" placeholder="Password" v-model="password">
                <input type="password" placeholder="Confirm Password" v-model="confirm">
                <Button arrow :disabled="!isReady">Register</Button>
            </form>
            <p class="error" v-if="error">{{ error }}</p>
        </div>
        <h4 class="login">Already have an account? <span class="gold" @click="$emit('login')">Login</span></h4>
    </div>
</template>

<script>

    // Components.
    import Button from '@/components/Button.vue';

    // Firebase.
    import { fetchUser, register } from '@/firebase/auth.js';

    export default {
        name: 'Register',
        components: {
            Button,
        },
        data() {
            return {
                email: '',
                password: '',
                confirm: '',
                error: null,
            }
        },
        computed: {
            isReady() {
                return (this.email !== '' && this.password !== '' && this.confirm !== '');
            }
        },
        methods: {
            submit(e) {

                e.preventDefault();

                this.error = null;
                this.$store.dispatch('updateLoading', 1);

                const email = this.email.trim();
                const password = this.password;
                const confirm = this.confirm;

                if (password !== confirm) {
                    this.error = `Passwords do not match.`;
                    this.$store.dispatch('updateLoading', -1);
                } else if (password.length < 6) {
                    this.error = `Password must be at least six characters.`;
                    this.$store.dispatch('updateLoading', -1);
                } else {
                    fetchUser(email).then(providers => {
                        if (providers.length === 0) return register(email, password);
                        else return Promise.reject(new Error(`An account already exists with this email.`));
                    }).then(() => {
                        this.$store.dispatch('updateLoading', -1);
                    }).catch(error => {
                        this.error = error.message;
                        this.$store.dispatch('updateLoading', -1);
                    });
                }

            },
        }
    }

</script>

<style lang="scss" scoped>

    @import '../styles/_variables.scss';

    .login {
        text-align: center;
        margin-bottom: size(Medium);
    }

</style>
