<template>
    <div>
        <div class="card card--form">
            <img class="lockup" src="/images/lockup.svg" alt="BARF Book">
            <form @submit="submit">
                <input type="email" placeholder="Email" v-model="email">
                <input type="password" placeholder="Password" v-model="password">
                <Button arrow :disabled="!isReady">Login</Button>
            </form>
            <p class="error" v-if="error">{{ error }}</p>
            <h4 class="forgot">Forgot your password? <span class="gold" @click="$emit('reset')">Reset it</span></h4>
            <div class="divider"><span>Or sign in with</span></div>
            <div class="social">
                <button @click="google"><img src="/images/auth/google.svg" alt="Google">Google</button>
                <p class="footnote">There is no need to register if you already have a Google account. Simply press the button above and log in to your Google account to continue.</p>
            </div>
        </div>
        <h4 class="register">Don't have an account? <span class="gold" @click="$emit('register')">Register</span></h4>
    </div>
</template>

<script>

    // Components.
    import Button from '@/components/Button.vue';

    // Firebase.
    import { fetchUser, login, googleLogin } from '@/firebase/auth.js';

    export default {
        name: 'Login',
        components: {
            Button,
        },
        data() {
            return {
                email: '',
                password: '',
                error: null,
            }
        },
        computed: {
            isReady() {
                return (this.email !== '' && this.password !== '');
            }
        },
        methods: {
            google() {
                googleLogin().then(result => {
                    // ...
                }).catch(error => {
                    console.error(error);
                });
            },
            submit(e) {

                e.preventDefault();

                this.error = null;
                this.$store.dispatch('updateLoading', 1);

                const email = this.email.trim();
                const password = this.password;

                fetchUser(email).then(providers => {
                    if (providers.length === 0) return Promise.reject(new Error('Email not found.'));
                    else return login(email, password);
                }).then(() => {
                    this.$store.dispatch('updateLoading', -1);
                }).catch(error => {
                    this.error = error;
                    this.$store.dispatch('updateLoading', -1);
                });

            },
        }
    }

</script>

<style lang="scss" scoped>

    @import '../styles/_variables.scss';

    .forgot {
        margin-top: size(Small);
    }

    .forgot,
    .register {
         text-align: center;
    }

    .divider {

        display: flex;
        align-items: center;

        font-weight: 700;
        font-size: 10px;
        text-transform: uppercase;

        opacity: 0.15;

        margin: size(Large) 0 size(Medium);

    }

    .divider:before,
    .divider:after {

        content: '';

        flex-grow: 1;

        height: 1px;
        background-color: color(Emperor);

    }

    .divider span {
        padding: 0 size(Medium);
    }

    .social button {

        appearance: none;
        outline: none;
        border: none;

        font-family: 'Open Sans', sans-serif;
        font-weight: 700;
        font-size: 12px;
        color: color(Emperor);
        text-align: center;

        border-radius: size(Micro);
        background: color(White);
        box-shadow: 0 0 15px color(Black, 0.05);

        height: size(Large);

        display: flex;
        align-items: center;
        justify-content: center;

        width: 100%;

    }

    .social button img {
        margin-right: size(Small);
    }

    .register {
        margin-bottom: size(Medium);
    }

    p.footnote {
        margin-top: size(Small);
    }

</style>
