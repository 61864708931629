const DAYS = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

/**
 * Convert a file to base 64.
 * @param { File } file - The file to convert.
 * @return { Promise } - The file as base 64.
 */
export function toBase64(file) {
    return new Promise((resolve, reject) => {

        const reader = new FileReader();

        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);

    });
}

/**
 * Format a date.
 * @param { Integer } timestamp - The timestamp to format.
 * @return { String } - The formatted timestamp.
 */
export function convertDate(timestamp) {

    const date  = new Date(timestamp);
    const month = date.getMonth() + 1;
    const day   = date.getDate();
    const year  = date.getFullYear();
    const hours = date.getHours();
    const mins  = date.getMinutes();
    const now   = Date.now();
    const diff  = (timestamp - now);

    // Inside of a week.
    if (Math.floor(diff / (24 * 60 * 60 * 1000)) < 7) {
        return `${ DAYS[date.getDay()] } ${ hours < 10 ? '0' + hours : hours }:${ mins < 10 ? '0' + mins : mins }`;
    }

    return `${ month < 10 ? '0' + month : month }/${ day < 10 ? '0' + day : day }/${ year }\n${ hours < 10 ? '0' + hours : hours }:${ mins < 10 ? '0' + mins : mins }`;

}

/**
 * Retrieve date from timestamp.
 * @param { Integer } timestamp - The timestamp.
 * @return { String } - The date.
 */
export function getDate(timestamp) {

    const date  = new Date(timestamp);
    const year  = date.getFullYear();
    const month = date.getMonth() + 1;
    const day   = date.getDate();

    return `${ year }-${ month < 10 ? '0' + month : month }-${ day < 10 ? '0' + day : day }`;

}

/**
 * Retrieve time from timestamp.
 * @param { Integer } timestamp - The timestamp.
 * @return { String } - The time.
 */
export function getTime(timestamp) {

    const date  = new Date(timestamp);
    const hours = date.getHours();
    const mins  = date.getMinutes();

    return `${ hours < 10 ? '0' + hours : hours }:${ mins < 10 ? '0' + mins : mins }`;

}

///

export function getTicketStatus(ticket, wagers) {

    // Single.
    if (ticket.options.bets.length === 1) {
        return getBetStatus(ticket.options.bets[0], wagers);
    // Parlay.
    } else {

        const results = ticket.options.bets.map(b => getBetStatus(b, wagers));
        const uniqueResults = [...new Set(results)];

        if (uniqueResults.includes('loss')) return 'loss';
        else if (uniqueResults.includes('push') && uniqueResults.length === 1) return 'push';
        else if (uniqueResults.includes('pending')) return 'pending';
        return 'win';

    }

}

export function getBetStatus(bet, wagers) {
    if (bet.wager[1].type === 'overUnder') {

        const finalTotal = wagers[bet.wager[0]].finalTotal;
        const boughtTotal = bet.wager[1].total;

        if (finalTotal === undefined) return 'pending';
        if (finalTotal === boughtTotal) return 'push';
        if ((finalTotal > boughtTotal && bet.selected === 0) || (finalTotal < boughtTotal && bet.selected === 1)) return 'win';
        return 'loss';

    } else {

        const winningLines = wagers[bet.wager[0]].winningLines;

        if (winningLines === undefined) return 'pending';
        if (winningLines.includes(bet.selected) && winningLines.length === 1) return 'win';
        if (winningLines.includes(bet.selected) && winningLines.length > 1) return 'push';
        return 'loss';

    }
}

export function adjustedOdds(ticket, wagers) {

    let odds = 1;

    ticket.options.bets.map(bet => {
        odds *= getBetStatus(bet, wagers) === 'push' ? 1 : bet.wager[1].odds[bet.selected];
    });

    return odds;

}
