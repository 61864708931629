// Modules.
import firebase from 'firebase/app';
// import 'firebase/auth';

// Firebase.
import { auth, database } from '@/firebase/config.js';

export function fetchUser(email) {
    return auth.fetchSignInMethodsForEmail(email);
};

export function login(email, password) {
    return auth.signInWithEmailAndPassword(email, password);
};

export function googleLogin() {
    return auth.signInWithPopup(new firebase.auth.GoogleAuthProvider());
};

export function register(email, password) {
    return auth.createUserWithEmailAndPassword(email, password);
};

export function logout() {
    return auth.signOut();
};

export function resetPassword(email) {
    return auth.sendPasswordResetEmail(email);
};

export function updatePassword(code, password) {
    return auth.confirmPasswordReset(code, password);
};

export async function isDuplicateUsername(username) {

    const snapshot = await database.ref('profiles').once('value');
    const profiles = snapshot.val();

    if (!profiles) return false;
    else {
        const matches = Object.values(profiles).filter(profile => profile.username.toLowerCase() === username.toLowerCase());
        return matches.length ? true : false;
    }

};

export function getProfile(userId) {
    return database.ref(`profiles/${ userId }`).once('value');
}

export function watchProfile(userId) {
    return database.ref(`profiles/${ userId }`);
}

export function getProfiles() {
    return database.ref('profiles').once('value');
}

export async function createProfile(userId, profile, isUpdating) {

    if (!isUpdating) {
        await database.ref(`profiles/${ userId }`).set(profile);
    } else {
        const p = (await database.ref(`profiles/${ userId }`).once('value')).val();
        await database.ref(`profiles/${ userId }`).set({ ...p, ...profile });
    }

    return database.ref(`profiles/${ userId }`).once('value');

};
