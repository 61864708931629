// Modules.
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';

const config = {
    apiKey: "AIzaSyB_rfApFuJx2RcQAzWnDbvFOt4JLcKX6Ms",
    authDomain: "barf-book.firebaseapp.com",
    databaseURL: "https://barf-book-default-rtdb.firebaseio.com",
    projectId: "barf-book",
    storageBucket: "barf-book.appspot.com",
    messagingSenderId: "375934221821",
    appId: "1:375934221821:web:2ca7bca3c18f295fbe2d94",
    measurementId: "G-XLPRSMB4WF"
};

firebase.initializeApp(config);

export const database = firebase.database();
export const auth = firebase.auth();
