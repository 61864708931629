// Modules.
import Vue from 'vue';
import VueRouter from 'vue-router';

// Components.
import Home from '@/views/Home.vue';

Vue.use(VueRouter);

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home
    },
    {
        path: '/reset',
        name: 'Reset',
        component: () => import('@/views/UpdatePassword.vue')
    },
    {
        path: '/books/:book_id',
        name: 'Books',
        component: () => import('@/views/Books.vue')
    },
    {
        path: '/bets/open',
        name: 'Bets',
        component: () => import('@/views/OpenBets.vue'),
    },
    {
        path: '/bets/settled',
        name: 'SettledBets',
        component: () => import('@/views/SettledBets.vue'),
    },
    {
        path: '/bets/voided',
        name: 'VoidedBets',
        component: () => import('@/views/VoidedBets.vue'),
    },
    {
        path: '/bets/profit',
        name: 'Profit',
        component: () => import('@/views/Profit.vue'),
    },
    {
        path: '/manage',
        name: 'Manage',
        component: () => import('@/views/Manage.vue')
    },
    {
        path: '/manage/books/create',
        name: 'CreateBook',
        component: () => import('@/views/CreateBook.vue')
    },
    {
        path: '/manage/members/:book_id',
        name: 'Members',
        component: () => import('@/views/Members.vue')
    },
    {
        path: '/manage/members/:book_id/invite',
        name: 'InviteMembers',
        component: () => import('@/views/InviteMembers.vue')
    },
    {
        path: '/manage/wagers/:book_id',
        name: 'Wagers',
        component: () => import('@/views/Wagers.vue')
    },
    {
        path: '/manage/wagers/:book_id/create',
        name: 'CreateWager',
        component: () => import('@/views/CreateWager.vue')
    },
    {
        path: '/manage/wagers/:book_id/edit/:wager_id',
        name: 'EditWager',
        component: () => import('@/views/EditWager.vue')
    },
    {
        path: '/manage/tickets/:book_id',
        name: 'Tickets',
        component: () => import('@/views/Tickets.vue')
    },
    {
        path: '/manage/margins/:book_id',
        name: 'Margins',
        component: () => import('@/views/Margins.vue')
    },
    {
        path: '/help',
        name: 'Help',
        component: () => import('@/views/Help.vue')
    },
    {
        path: '/profile',
        name: 'Profile',
        component: () => import('@/views/EditProfile.vue')
    },
    {
        path: '/alerts',
        name: 'Alerts',
        component: () => import('@/views/Alerts.vue')
    },
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
});

export default router;
