<template>
    <div class="Auth">
        <video src="/videos/promo.mp4" muted autoplay loop playsinline />
        <Login v-if="isLogin" @reset="changeMode('RESET')" @register="changeMode('REGISTER')" />
        <Register v-if="isRegister" @login="changeMode('LOGIN')" />
        <ResetPassword v-if="isReset" @login="changeMode('LOGIN')" />
        <Profile v-if="isProfile" />
    </div>
</template>

<script>

    // Components.
    import Login from '@/components/Login.vue';
    import Register from '@/components/Register.vue';
    import ResetPassword from '@/components/ResetPassword.vue';
    import Profile from '@/components/Profile.vue';

    const MODES = {
        LOGIN:    'LOGIN',
        REGISTER: 'REGISTER',
        RESET:    'RESET',
    };

    export default {
        name: 'Auth',
        components: {
            Login,
            Register,
            ResetPassword,
            Profile,
        },
        data() {
            return {
                mode: MODES.LOGIN,
            }
        },
        computed: {
            isLogin() {
                return (this.mode === MODES.LOGIN && !this.$store.state.user);
            },
            isRegister() {
                return (this.mode === MODES.REGISTER && !this.$store.state.user);
            },
            isReset() {
                return (this.mode === MODES.RESET && !this.$store.state.user);
            },
            isProfile() {
                return this.$store.state.user;
            },
        },
        methods: {
            changeMode(mode) {
                this.mode = mode;
            },
        },
    }

</script>

<style lang="scss" scoped>

    @import '../styles/_variables.scss';

    video {
        display: block;
        width: 100%;
    }

</style>

<style lang="scss">

    @import '../styles/_variables.scss';

    .Auth .card {
        position: relative;
        margin: (size(Large) * -1) auto size(Medium);
    }

    .Auth .lockup {
        width: 100%;
        margin-bottom: size(Large);
    }

    .Auth input:not(:first-of-type),
    .Auth .Button {
        margin-top: size(Small);
    }

    .Auth span.gold {
        color: color(OldGold);
    }

    .Auth .error {
        margin-top: size(Small);
    }

</style>
