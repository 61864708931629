// Modules.
import Vue from 'vue';
import { mapState } from 'vuex';
import PrismicVue from '@prismicio/vue';

// Views.
import App from '@/App.vue';

// Styles.
import '@/styles/index.scss';

// Misc.
import router from '@/router';
import store from '@/store';

Vue.config.productionTip = false;

Vue.mixin({
    computed: {
        ...mapState(['user', 'profile', 'hasAuthed']),
        authenticated() {
            return (store.state.user && store.state.profile);
        },
    }
});

Vue.use(PrismicVue, {
    endpoint: 'https://barf-book.cdn.prismic.io/api/v2',
});

new Vue({
    router,
    store,
    render: h => h(App),
}).$mount('#app');
