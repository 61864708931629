<template>
    <button :class="rootClass" :disabled="disabled" @click="$emit('onClick')">
        <span><slot /></span>
        <span class="arrow" v-if="arrow"><Arrow /></span>
    </button>
</template>

<script>

    // Modules.
    import classnames from 'classnames';

    // Components.
    import Arrow from '@/components/vectors/Arrow.vue';

    export default {
        name: 'Button',
        components: {
            Arrow,
        },
        props: {
            arrow: { type: Boolean, default: false },
            theme: { type: String, default: 'gold', validator: value => {
                return ['gold', 'grey', 'red'].indexOf(value) !== -1;
            }},
            disabled: { type: Boolean, default: false },
        },
        computed: {
            rootClass() {
                return classnames({
                    'Button': true,
                    [this.$props.theme]: true,
                });
            }
        }
    }

</script>

<style lang="scss" scoped>

    @import '../styles/_variables.scss';

    .Button {

        position: relative;

        appearance: none;
        outline: none;
        border: none;

        font-family: 'Open Sans', sans-serif;
        font-weight: 700;
        font-size: 12px;
        color: color(White);

        border-radius: size(Micro);
        box-shadow: 0 0 15px color(Black, 0.05);

        width: 100%;
        height: size(Large);
        padding: 0 size(Medium);

        transition: transform 250ms ease-in-out;

    }

    .Button:disabled {
        opacity: 0.25;
        pointer-events: none;
    }

    .Button:active {
        transform: scale(0.95);
    }

    .Button.gold {
        background: color(OldGold);
    }

    .Button.grey {
        background: color(Emperor);
    }

    .Button.red {
        background: color(Carmine);
    }

    .arrow {

        position: absolute;
        top: 50%;
        right: size(Medium);

        width: 18px;
        height: 18px;

        transform: translateY(-50%);

    }

</style>
