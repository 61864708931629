// Modules.
import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        loadingCount: 0,
        hasAuthed: false,
        user: null,
        profile: null,
        books: {},
        hideNavShadow: false,
        hasUnreadAlerts: false,
    },
    mutations: {
        reset(state) {
            state.hasAuthed = false;
            state.user = null;
            state.profile = null;
            state.books = {};
        },
        updateLoading(state, count) {
            let clampedCount = Math.max(0, state.loadingCount += count);
            state.loadingCount = clampedCount;
        },
        setHasAuthed(state, hasAuthed) {
            state.hasAuthed = hasAuthed;
        },
        setUser(state, user) {
            state.user = user;
        },
        setProfile(state, profile) {
            state.profile = profile;
        },
        setBook(state, book) {
            state.books = {
                ...state.books,
                ...book,
            };
        },
        setNavShadow(state, hideShadow) {
            state.hideNavShadow = hideShadow;
        },
        setHasUnreadAlerts(state, hasUnreadAlerts) {
            state.hasUnreadAlerts = hasUnreadAlerts;
        }
    },
    actions: {
        reset({ commit }) {
            commit('reset');
        },
        updateLoading({ commit }, count) {
            commit('updateLoading', count);
        },
        setProfile({ commit }, profile) {
            commit('setProfile', profile);
        },
        auth({ commit }, data) {
            commit('setHasAuthed', true);
            commit('setUser', data.user);
            commit('setProfile', data.profile);
        },
        setBook({ commit }, book) {
            commit('setBook', book);
        },
        setNavShadow({ commit }, hideShadow) {
            commit('setNavShadow', hideShadow);
        },
        setAlerts({ commit }, alerts) {

            if (alerts) {

                let hasUnreadAlerts = false;

                for (let alert of Object.values(alerts)) {
                    if (!alert.read) {
                        hasUnreadAlerts = true;
                        break;
                    }
                }

                commit('setHasUnreadAlerts', hasUnreadAlerts);

            }

        }
    },
    modules: {
    }
});
