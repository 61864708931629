<template>
    <section id="Home">
        <Auth v-if="isAuth" />
        <div v-else class="welcome card card--form">
            <h3>Welcome!</h3>
            <p>It doesn't look like you have any books on your account. A book, short for "sportsbook", is a set of wagers available for betting.</p>
            <p>If you're a new user and you plan on just making bets, wait for your bookie to invite you to their book.</p>
            <p>If you're a new user and you intend on playing the role of bookie, press the button below to create a new book.</p>
            <router-link :to="{ name: 'CreateBook' }">
                <Button arrow>Create Book</Button>
            </router-link>
        </div>
    </section>
</template>

<script>

    // Components.
    import Auth from '@/components/Auth.vue';
    import Button from '@/components/Button.vue';

    export default {
        name: 'Home',
        components: {
            Auth,
            Button,
        },
        computed: {
            isAuth() {
                return (!this.hasAuthed || !this.user || !this.profile);
            }
        },
        mounted() {
            this.$store.dispatch('setNavShadow', false);
        },
        created() {
            if (!this.isAuth && this.profile.books) {
                for (const book of Object.entries(this.profile.books))  {
                    if (book[1].membership === 'member' || book[1].membership === 'admin' || book[1].membership === 'bookie') {
                        this.$router.replace({ name: 'Books', params: { book_id: book[0] } });
                        break;
                    }
                }
            }
        },
        watch: {
            profile(to, from) {
                if (!from && to && to.books) {
                    for (const book of Object.entries(to.books))  {
                        if (book[1].membership === 'member' || book[1].membership === 'admin' || book[1].membership === 'bookie') {
                            this.$router.replace({ name: 'Books', params: { book_id: book[0] } });
                            break;
                        }
                    }
                }
            }
        }
    }

</script>

<style lang="scss" scoped>

    @import '../styles/_variables.scss';

    .welcome {
        margin: size(Medium) auto;
    }

    .welcome p {
        margin-top: size(Small);
    }

    .welcome .Button {
        margin-top: size(Large);
    }

</style>
