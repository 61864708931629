<template>
    <div id="app" :class="{ bump: authenticated }">
        <NavBar v-if="authenticated" @openPersonal="menu = 'personal'" @openPages="menu = 'pages'" />
        <Menu v-if="authenticated && menu" :sidebar="menu" @close="menu = null" />
        <router-view v-if="hasAuthed" />
        <transition name="fade">
            <Loading v-if="isLoading" />
        </transition>
    </div>
</template>

<script>

    // Components.
    import Loading from '@/components/Loading.vue';
    import NavBar from '@/components/NavBar.vue';
    import Menu from '@/components/Menu.vue';

    // Firebase.
    import { auth } from '@/firebase/config.js';
    import { watchProfile } from '@/firebase/auth.js';
    import { getAlerts } from '@/firebase/alerts.js';

    export default {
        name: 'App',
        components: {
            Loading,
            NavBar,
            Menu,
        },
        data() {
            return {
                menu: null,
            }
        },
        computed: {
            isLoading() {
                return (this.$store.state.loadingCount > 0 || !this.$store.state.hasAuthed);
            }
        },
        created() {

            auth.onAuthStateChanged(async user => {

                if (user) {

                    watchProfile(user.uid).on('value', snapshot => {

                        if (!this.user) {
                            this.$store.dispatch('auth', { user, profile: snapshot.val() });
                        } else {
                            this.$store.dispatch('setProfile', snapshot.val());
                        }

                        getAlerts(user.uid).then(snapshot => {
                            this.$store.dispatch('setAlerts', snapshot.val());
                        });

                    });

                } else {
                    this.$store.dispatch('auth', { user: null, profile: null });
                }

            });

        },
    }

</script>

<style lang="scss" scoped>

    @import './styles/_variables.scss';

    #app.bump {
        padding-top: 50px;
    }

    .fade-enter, .fade-leave-to {
        opacity: 0;
    }

    .fade-enter-active, .fade-leave-active {
        transition: opacity 500ms ease;
    }

</style>
