<template>
    <div>
        <div class="card card--form">
            <img class="lockup" src="/images/lockup.svg" alt="BARF Book">
            <form @submit="submit" v-if="!success">
                <input type="email" placeholder="Email" v-model="email">
                <Button arrow :disabled="!isReady">Reset Password</Button>
            </form>
            <p class="error" v-if="error">{{ error }}</p>
            <div class="success" v-if="success">
                <h3>On its way!</h3>
                <p>A password reset link has been sent. You should see it in your inbox shortly.</p>
            </div>
        </div>
        <h4 class="login">Already have an account? <span class="gold" @click="$emit('login')">Login</span></h4>
    </div>
</template>

<script>

    // Components.
    import Button from '@/components/Button.vue';

    // Firebase.
    import { resetPassword } from '@/firebase/auth.js';

    export default {
        name: 'ResetPassword',
        components: {
            Button,
        },
        data() {
            return {
                email: '',
                error: null,
                success: false,
            }
        },
        computed: {
            isReady() {
                return (this.email !== '');
            }
        },
        methods: {
            submit(e) {

                e.preventDefault();

                this.error = null;
                this.$store.dispatch('updateLoading', 1);

                const email = this.email.trim();

                resetPassword(email).then(() => {
                    this.success = true;
                    this.$store.dispatch('updateLoading', -1);
                }).catch(error => {
                    this.error = error;
                    this.$store.dispatch('updateLoading', -1);
                });

            }
        }
    }

</script>

<style lang="scss" scoped>

    @import '../styles/_variables.scss';

    .login {
        text-align: center;
        margin-bottom: size(Medium);
    }

    .success p {
        margin-top: size(Small);
    }

</style>
