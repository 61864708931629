<template>
    <nav class="NavBar" :class="{ ['hide-shadow']: $store.state.hideNavShadow }">
        <div class="burger" @click="$emit('openPages')"><span>Menu</span></div>
        <div class="avatar-wrapper">
            <div class="avatar" :style="{ backgroundImage: `url(${ profile.avatar })` }" @click="$emit('openPersonal')" />
            <div class="alert" v-if="$store.state.hasUnreadAlerts" />
        </div>
        <router-link :to="{ name: 'Home' }" class="lockup"><img src="/images/lockup.svg" alt="BARF Book"></router-link>
    </nav>
</template>

<script>

    export default {
        name: 'NavBar',
    }

</script>

<style lang="scss" scoped>

    @import '../styles/_variables.scss';

    .NavBar {

        position: fixed;
        top: 0;
        left: 0;
        z-index: 50;

        display: flex;
        align-items: center;
        justify-content: space-between;

        width: 100%;
        height: 50px;
        padding: 0 size(Medium);

        background-color: color(White);
        box-shadow: 0 0 15px color(Black, 0.1);

    }

    .NavBar.hide-shadow {
        box-shadow: none;
    }

    .burger {
        font-size: 8px;
        font-weight: 700;
        text-transform: uppercase;
    }

    .burger:before,
    .burger:after {

        content: '';

        display: block;

        width: 100%;
        height: 0.175em;

        background-color: color(Emperor);

    }

    .burger span {
        display: block;
        margin: 3px 0;
    }

    .lockup {

        position: absolute;
        top: 50%;
        left: 50%;

        transform: translateX(-50%) translateY(-50%);

        height: size(Large);

        display: block;

    }

    .lockup img {
        height: 100%;
    }

    .avatar-wrapper {
        position: relative;
    }

    .alert {

        position: absolute;
        top: 0;
        right: 0;

        transform: translateX(25%) translateY(-25%);

    }

</style>
