<template>
    <button class="Close" @click="$emit('onClick')" />
</template>

<script>

    export default {
        name: 'Close',
    }

</script>

<style lang="scss" scoped>

    @import '../styles/_variables.scss';

    .Close {

        position: relative;

        appearance: none;
        outline: none;
        border: none;

        border-radius: 50%;

        width: 18px;
        height: 18px;
        padding: 0;

        background: color(Emperor);

    }

    .Close:before,
    .Close:after {

        content: '';

        position: absolute;
        top: 50%;
        left: 50%;

        width: 50%;
        height: 1px;

        background-color: color(White);

    }

    .Close:before {
        transform: translateX(-50%) translateY(-50%) rotate(45deg);
    }

    .Close:after {
        transform: translateX(-50%) translateY(-50%) rotate(-45deg);
    }

</style>
