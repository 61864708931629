<template>
    <div>
        <div class="card card--form" v-if="!success">
            <img v-if="!edit" class="lockup" src="/images/lockup.svg" alt="BARF Book">
            <form @submit="submit">
                <p v-if="!edit">It looks like you haven’t added any profile information for your account yet. Complete the fields below so other users can see who you are.</p>
                <h3>{{ header }}</h3>
                <input type="text" placeholder="First Name" v-model="firstName">
                <input type="text" placeholder="Last Name" v-model="lastName">
                <input type="text" placeholder="Username" v-model="username" :disabled="edit">
                <p v-if="!edit" class="footnote">This is how friends will search for you and add you to books. Make it memorable.</p>
                <p v-if="edit" class="footnote">You can't change your username once you've created your profile.</p>
                <h3>Avatar</h3>
                <div class="avatar" v-if="!hasChangedAvatar && edit" :style="{ backgroundImage: `url('${ profile && profile.avatar }')` }" />
                <div class="avatar" v-if="base64" :style="{ backgroundImage: `url('${ base64 }')` }" />
                <div class="file">
                    <label :class="`${ avatar && 'chosen' }`">
                        <input type="file" @change="onChange" ref="avatar">
                        <span>{{ filename }}</span>
                    </label>
                    <Close v-if="avatar" @onClick="clear" />
                </div>
                <Button arrow :disabled="!isReady">{{ submitLabel }}</Button>
            </form>
            <p class="error" v-if="error">{{ error }}</p>
        </div>
        <div class="success card card--form" v-if="success">
            <h3>Success!</h3>
            <p>Your profile has been updated. Press the button below to return to your profile.</p>
            <Button arrow @onClick="success = false">Return</Button>
        </div>
    </div>
</template>

<script>

    // Modules.
    import axios from 'axios';

    // Components.
    import Button from '@/components/Button.vue';
    import Close from '@/components/Close.vue';

    // Firebase.
    import { createProfile, isDuplicateUsername } from '@/firebase/auth.js';

    // Misc.
    import { toBase64 } from '@/utils.js';

    export default {
        name: 'Profile',
        components: {
            Button,
            Close,
        },
        data() {
            return {
                firstName: '',
                lastName: '',
                username: '',
                avatar: null,
                base64: null,
                error: null,
                hasChangedAvatar: false,
                success: false,
            }
        },
        props: {
            edit: Boolean,
        },
        computed: {
            isReady() {
                if (this.$props.edit && !this.hasChangedAvatar) return (this.firstName.trim() !== '' && this.lastName.trim() !== '' && this.username.trim() !== '');
                return (this.firstName.trim() !== '' && this.lastName.trim() !== '' && this.username.trim() !== '' && this.avatar);
            },
            filename() {
                return (this.avatar && this.avatar.name) || 'No File Chosen';
            },
            header() {
                return this.$props.edit ? 'Edit Profile' : 'Profile';
            },
            submitLabel() {
                return this.$props.edit ? 'Update' : 'Save';
            }
        },
        created() {
            if (this.profile) {
                this.firstName = this.profile.firstName;
                this.lastName = this.profile.lastName;
                this.username = this.profile.username;
            }
        },
        methods: {
            async submit(e) {

                e.preventDefault();

                if (!this.hasChangedAvatar) {
                    this.update();
                    return;
                }

                this.error = null;
                this.$store.dispatch('updateLoading', 1);

                const firstName = this.firstName.trim().replace('/', '-');
                const lastName = this.lastName.trim().replace('/', '-');
                const username = this.username.trim().replace('/', '-');

                // Ensure the username is unique.
                if (await isDuplicateUsername(username) && !this.$props.edit) {

                    this.error = 'A profile already exists with this username.';
                    this.$store.dispatch('updateLoading', -1);

                    return;

                }

                // Ensure the avatar is less than 2MB.
                if ((this.avatar.size / 1024 / 1024) > 2) {

                    this.error = 'Avatars must be less than 2MB.';
                    this.$store.dispatch('updateLoading', -1);

                    return;

                }

                axios.post('https://us-central1-chili-dip-2.cloudfunctions.net/upload', {
                    image: this.base64,
                }).then(result => {

                    if (!result.data.error) {
                        createProfile(this.user.uid, {
                            firstName, lastName, username, avatar: result.data.src,
                        }, this.profile && this.hasChangedAvatar).then(snapshot => {
                            this.success = true;
                            this.$store.dispatch('setProfile', snapshot.val());
                            this.$store.dispatch('updateLoading', -1);
                        }).catch(error => {
                            this.error = error.message;
                            console.error(error);
                            this.$store.dispatch('updateLoading', -1);
                        });
                    } else {
                        this.error = 'There was an error uploading the avatar.';
                        this.$store.dispatch('updateLoading', -1);
                    }

                }).catch(error => {
                    this.error = error;
                    console.error(error);
                    this.$store.dispatch('updateLoading', -1);
                });

            },
            async update() {

                this.error = null;
                this.$store.dispatch('updateLoading', 1);

                const firstName = this.firstName.trim();
                const lastName = this.lastName.trim();
                const username = this.username.trim();

                createProfile(this.user.uid, {
                    firstName, lastName, username, avatar: this.profile.avatar,
                }, true).then(snapshot => {
                    this.success = true;
                    this.$store.dispatch('setProfile', snapshot.val());
                    this.$store.dispatch('updateLoading', -1);
                }).catch(error => {
                    this.error = error.message;
                    console.error(error);
                    this.$store.dispatch('updateLoading', -1);
                });

            },
            async onChange(e) {
                this.hasChangedAvatar = true;
                this.avatar = e.target.files[0];
                this.base64 = await toBase64(this.avatar);
            },
            clear() {
                this.$refs.avatar.value = '';
                this.avatar = null;
                this.base64 = null;
            }
        }
    }

</script>

<style lang="scss" scoped>

    @import '../styles/_variables.scss';

    p.footnote {
        margin-top: size(Small);
    }

    h3 {
        margin: size(Medium) 0 size(Small);
    }

    .avatar {

        width: 100px;
        height: 100px;
        margin-bottom: size(Small);

        background-color: color(White);
        background-size: cover;
        background-position: center center;

        border-radius: 50%;
        border: 2px solid color(OldGold);

        box-shadow: 0 5px 15px color(Black, 0.2);

    }

    .file {
        position: relative;
    }

    input[type=file] {
        display: none;
    }

    .Close {

        position: absolute;
        top: 50%;
        left: calc(100% + #{size(Micro)});

        transform: translateY(-50%);

    }

</style>
